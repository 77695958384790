/*

DayPilot Calendar Theme
http://themes.daypilot.org/calendar/theme/japngi

*/

.calendar_traditional_main {
    border: 1px solid #999999;
}

.calendar_traditional_main, .calendar_traditional_main td {
    font-family: Tahoma, Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.calendar_traditional_event {

}

.calendar_traditional_event_inner {
    position: absolute;
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: 0px;
    color: #333333;
    background-color: #ffffff;
    background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2);
    padding: 3px;
    padding-left: 9px;
    border: 1px solid #999999;
}

.calendar_traditional_alldayevent {

}

.calendar_traditional_alldayevent_inner {
    position: absolute;
    overflow: hidden;
    left: 1px;
    right: 0px;
    top: 1px;
    bottom: 0px;
    margin: 0px;
    color: #333333;
    background-color: #ffffff;
    background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2);
    padding: 3px;
    border: 1px solid #999999;
    text-align: left;
}

.calendar_traditional_alldayheader_inner {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #999999;
    border-bottom: 1px solid #999999;
    color: #333333;
    background: #ece9d8;
    background: linear-gradient(to bottom, #efecde 0%, #e9e6d2);
}

.calendar_traditional_colheader_inner {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #999999;
    border-bottom: 1px solid #999999;
    color: #333333;
    background: #ece9d8;
    background: linear-gradient(to bottom, #efecde 0%, #e9e6d2);
    display: flex; align-items: center; justify-content: center;
}

.calendar_traditional_rowheader_inner {
    font-size: 16pt;

    text-align: right;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #999999;
    border-bottom: 1px solid #999999;
    color: #333333;
    background: #ece9d8;
    background: linear-gradient(to right, #efecde 0%, #e9e6d2);
}

.calendar_traditional_rowheader_minutes {
    font-size: 10px;
    vertical-align: super;
    padding-left: 2px;
    padding-right: 2px;
}

.calendar_traditional_corner_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #999999;
    border-bottom: 1px solid #999999;
    color: #333333;
    background: #ece9d8;
    background: linear-gradient(to right, #efecde 0%, #e9e6d2);
}

.calendar_traditional_cornerright_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #999999;
    border-bottom: 1px solid #999999;
    color: #333333;
    background: #ece9d8;
    background: linear-gradient(to bottom, #efecde 0%, #e9e6d2);
}

.calendar_traditional_rowheader_inner {
    padding: 2px;
}

.calendar_traditional_cell_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #ead098;
    border-bottom: 1px solid #ead098;
    background: #fff4bc;
}

.calendar_traditional_cell_business .calendar_traditional_cell_inner {
    background: #ffffd5;
}

.calendar_traditional_message {
    padding: 10px;
    opacity: 0.9;
    filter: alpha(opacity=90);
    color: #ffffff;
    background: #777777;
    background: linear-gradient(to bottom, #838383 0%, #6b6b6b);
}

.calendar_traditional_shadow_inner {
    background-color: #666666;
    opacity: 0.5;
    filter: alpha(opacity=50);
    height: 100%;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.calendar_traditional_event_bar {
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 5px;
    background-color: #ffffff;

    border-left: 1px solid #999;
}

.calendar_traditional_event_bar_inner {
    position: absolute;
    width: 5px;
    background-color: #2d5de0;
}

.calendar_traditional_event_delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAI5JREFUKFNtkLERgCAMRbmzdK8s4gAUlhYOYEHJEJYOYOEwDmGBPxC4kOPfvePy84MGR0RJ2N1A8H3N6DATwSQ57m2ql8NBG+AEM7D+UW+wjdfUPgerYNgB5gOLRHqhcasg84C2QxPMtrUhSqQIhg7ypy9VM2EUZPI/4rQ7rGxqo9sadTegw+UdjeDLAKUfhbaQUVPIfJYAAAAASUVORK5CYII=) center center no-repeat;
    opacity: 0.6;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
    cursor: pointer;
}

.calendar_traditional_event_delete:hover {
    opacity: 1;
    -ms-filter: none;
}

.calendar_traditional_scroll_up {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAB3RJTUUH2wESDiYcrhwCiQAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAARnQU1BAACxjwv8YQUAAACcSURBVHjaY2AgF9wWsTW6yGMlhi7OhC7AyMDQzMnBXIpFHAFuCtuaMTP+P8nA8P/b1x//FfW/HHuF1UQmxv+NUP1c3OxMVVhNvCVi683E8H8LXOY/w9+fTH81tF8fv4NiIpBRj+YoZtZ/LDUoJmKYhsVUpv0MDiyMDP96sIYV0FS2/8z9ICaLlOhvS4b/jC//MzC8xBG0vJeF7GQBlK0xdiUzCtsAAAAASUVORK5CYII=);
}

.calendar_traditional_scroll_down {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAALiMAAC4jAXilP3YAAACqSURBVChTY7wpam3L9J+xmQEP+PGPKZZxP4MDi4zI78uMDIwa2NT+Z2DYovrmiC+TI8OBP/8ZmEqwGvif4e8vxr+FIDkmEKH25vBWBgbG0+iK/zEwLtF+ffwOXCGI8Y+BoRFFIdC030x/WmBiYBNhpgLdswNJ8RSYaSgmgk39z1gPUfj/29ef/9rwhQTDHRHbrbdEbLvRFcGthkkAra/9/uMvhkK8piNLAgCRpTnNn4AEmAAAAABJRU5ErkJggg==);
}

.calendar_traditional_now {
    background-color: red;
}

.calendar_traditional_now:before {
    content: '';
    top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent red;
    border-style: solid;
    width: 0px;
    height: 0px;
    position: absolute;
    -moz-transform: scale(.9999);
}

.calendar_traditional_shadow_forbidden .calendar_traditional_shadow_inner {
    background-color: red;
}

.calendar_traditional_shadow_top {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.calendar_traditional_shadow_bottom {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.calendar_traditional_crosshair_vertical, .calendar_traditional_crosshair_horizontal, .calendar_traditional_crosshair_left, .calendar_traditional_crosshair_top {
    background-color: gray;
    opacity: 0.2;
    filter: alpha(opacity=20)
}

.calendar_traditional_loading {
    background-color: orange;
    color: white;
    padding: 2px;
}
