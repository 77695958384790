/* calendar transparent */

.calendar_transparent_main {
    --color-header-background: #eeeeee;
    --color-header-text: #666666;
    --color-vertical-line-header: #dddddd;
    --color-vertical-line-cell: #eeeeee;
    --color-cell-background-business: #ffffff;
    --color-cell-background-nonbusiness: #f9f9f9;
    --color-event-background: #419FE0a2;
    --color-event-border: #47afff;
    --color-event-bar: #1066a8;
    --color-event-bar-background: transparent;
    --color-event-text: #444444;
    --width-vertical-line: 4px;
}

.calendar_transparent_main {
    border: 1px solid #aaa;
    font-size: 13px;
}

.calendar_transparent_colheader {
}

.calendar_transparent_cornerright_inner,
.calendar_transparent_corner_inner,
.calendar_transparent_colheader_inner,
.calendar_transparent_alldayheader_inner {
    color: var(--color-header-text);
    background-color: var(--color-header-background);
}

.calendar_transparent_rowheader_inner {
    color: var(--color-header-text);
    background: var(--color-header-background);
}

.calendar_transparent_cornerright_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-bottom: 1px solid #aaa;
}

.calendar_transparent_rowheader_inner {
    font-size: 16pt;
    text-align: right;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    padding-right: 4px;
    border-right: var(--width-vertical-line) solid var(--color-vertical-line-header);
    border-bottom: 1px solid #aaa;

}

.calendar_transparent_corner_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: var(--width-vertical-line) solid var(--color-vertical-line-header);
    border-bottom: 1px solid #aaa;
}

.calendar_transparent_rowheader_minutes {
    font-size: 10px;
    vertical-align: super;
    padding-left: 2px;
    padding-right: 2px;
}

.calendar_transparent_colheader_inner {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: var(--width-vertical-line) solid var(--color-vertical-line-header);
    border-bottom: 1px solid #aaa;

    display: flex; align-items: center; justify-content: center;
}

.calendar_transparent_cell_business
.calendar_transparent_cell_inner {
    background-color: var(--color-cell-background-business);
}

.calendar_transparent_cell_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: var(--width-vertical-line) solid var(--color-vertical-line-cell);
    border-bottom: 1px solid #ddd;

    background-color: var(--color-cell-background-nonbusiness);

}

.calendar_transparent_alldayheader_inner {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: var(--width-vertical-line) solid var(--color-vertical-line-header);
    border-bottom: 1px solid #999;

}

.calendar_transparent_message {
    opacity: 0.7;
    padding: 10px;
    color: #eeeeee;
    background: #333;
}

.calendar_transparent_alldayevent_inner,
.calendar_transparent_event_inner {
    color: var(--color-event-text);
    background: var(--color-event-background);
    border: 1px solid var(--color-event-border);
}

.calendar_transparent_selected .calendar_transparent_event_inner {
    background: #ddd;
}

.calendar_transparent_alldayevent_inner {
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 1px;
    right: 4px;
    padding: 4px;
    margin-right: 1px;

    font-size: 13px;
    border-left: 5px solid #1066a8;
    display: flex;
    align-items: center;
}

.calendar_transparent_event_withheader .calendar_transparent_event_inner {
    padding-top: 15px;
}

.calendar_transparent_event {
    cursor: default;
}

.calendar_transparent_event_hover {
    opacity: 0.9;
}

.calendar_transparent_alldayevent_hover {
    opacity: 0.9;
}

.calendar_transparent_event_inner {
    position: absolute;
    overflow: hidden;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    padding: 2px;
    padding-left: 12px; /* space for duration bar */

    font-size: 13px;
}

.calendar_transparent_shadow_inner {
    background-color: #666666;
    opacity: 0.5;
    height: 100%;
}

.calendar_transparent_event_bar {
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 6px;
    background-color: var(--color-event-bar-background);
}

.calendar_transparent_event_bar_inner {
    position: absolute;
    width: 6px;
    background-color: var(--color-event-bar);
}

.calendar_transparent_alldayevent_continueright:after {
    content: "";
    border-color: transparent transparent transparent #1066a8;
    border-style: solid;
    border-width: 5px;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 8px;
    right: -3px;
}

.calendar_transparent_alldayevent_continueleft:after {
    content: "";
    border-color: transparent #1066a8 transparent transparent;
    border-style: solid;
    border-width: 5px;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 8px;
    left: -5px;
}

.calendar_transparent_alldayevent_continueright .calendar_transparent_alldayevent_inner {
    border-right: 1px solid #1066a8;
}

.calendar_transparent_alldayevent_continueleft .calendar_transparent_alldayevent_inner {
    padding-left: 6px;
    border-left: 1px solid #1066a8;
}

.calendar_transparent_event_delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAI5JREFUKFNtkLERgCAMRbmzdK8s4gAUlhYOYEHJEJYOYOEwDmGBPxC4kOPfvePy84MGR0RJ2N1A8H3N6DATwSQ57m2ql8NBG+AEM7D+UW+wjdfUPgerYNgB5gOLRHqhcasg84C2QxPMtrUhSqQIhg7ypy9VM2EUZPI/4rQ7rGxqo9sadTegw+UdjeDLAKUfhbaQUVPIfJYAAAAASUVORK5CYII=) center center no-repeat;
    opacity: 0.6;
    cursor: pointer;
}

.calendar_transparent_event_delete:hover {
    opacity: 1;
    -ms-filter: none;
}

.calendar_transparent_scroll_up {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAB3RJTUUH2wESDiYcrhwCiQAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAARnQU1BAACxjwv8YQUAAACcSURBVHjaY2AgF9wWsTW6yGMlhi7OhC7AyMDQzMnBXIpFHAFuCtuaMTP+P8nA8P/b1x//FfW/HHuF1UQmxv+NUP1c3OxMVVhNvCVi683E8H8LXOY/w9+fTH81tF8fv4NiIpBRj+YoZtZ/LDUoJmKYhsVUpv0MDiyMDP96sIYV0FS2/8z9ICaLlOhvS4b/jC//MzC8xBG0vJeF7GQBlK0xdiUzCtsAAAAASUVORK5CYII=);
}

.calendar_transparent_scroll_down {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAALiMAAC4jAXilP3YAAACqSURBVChTY7wpam3L9J+xmQEP+PGPKZZxP4MDi4zI78uMDIwa2NT+Z2DYovrmiC+TI8OBP/8ZmEqwGvif4e8vxr+FIDkmEKH25vBWBgbG0+iK/zEwLtF+ffwOXCGI8Y+BoRFFIdC030x/WmBiYBNhpgLdswNJ8RSYaSgmgk39z1gPUfj/29ef/9rwhQTDHRHbrbdEbLvRFcGthkkAra/9/uMvhkK8piNLAgCRpTnNn4AEmAAAAABJRU5ErkJggg==);
}

.calendar_transparent_now {
    background-color: red;
}

.calendar_transparent_now:before {
    content: '';
    top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent red;
    border-style: solid;
    width: 0px;
    height: 0px;
    position: absolute;
    -moz-transform: scale(.9999);
}

.calendar_transparent_shadow_forbidden .calendar_transparent_shadow_inner {
    background-color: red;
}

.calendar_transparent_shadow_top {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.calendar_transparent_shadow_bottom {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.calendar_transparent_crosshair_vertical, .calendar_transparent_crosshair_horizontal, .calendar_transparent_crosshair_left, .calendar_transparent_crosshair_top {
    background-color: gray;
    opacity: 0.2;
}

.calendar_transparent_loading {
    background-color: orange;
    color: white;
    padding: 2px;
}
