/* You can add global styles to this file, and also import other style files */
@import url('./ethele-theme.css');
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");

@import 'themes/calendar_green.css';
@import 'themes/calendar_traditional.css';
@import 'themes/calendar_transparent.css';
@import 'themes/calendar_white.css';


html, body { height: 100%; }
body { margin: 0 !important; font-family: Roboto, "Helvetica Neue", sans-serif; }



body {
    animation: fadeInAnimation ease 1s;
    // background-image: linear-gradient(to left,grey, orange);
}

mat-card {
    animation: fadeInAnimation ease 1s;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.listBox {
    padding: 4px; 
    border: 1px solid white; 
    border-radius: 5px; 
    width: 30vw; 
    height: 30vh;
    background: radial-gradient(transparent, black);
}

.listItem:hover {
    background-color: #ffe7db;
    color: black;
    border-radius: 10px;
    cursor: pointer;
}

.listItem {
    cursor: pointer;
}

.cover {
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 0.7;
    position: fixed;
    top:0px;
    left: 0px;
    z-index: 2000;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
    overflow-y: scroll;
    // background: grey;
    box-shadow:  inset 0 0 5px darkgray;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px darkgray; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: darkgray; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ff5b0c; 
}
::-webkit-scrollbar-corner {
    background: grey;
}
// ::-webkit-scrollbar-track-piece{
//     background: black;
// }


.btn {
    text-decoration: none;
    // border: 4px solid rgb(146, 148, 248);
    border: 4px solid #f37321;
    border-radius: 2%;
    position: relative;
    overflow: hidden;

    text-transform: uppercase;
    font-size: 20px;
    background-color: rgb(100,100,100);
    transition: all 450ms;
  }
  
  .btn:hover {
    // box-shadow: 1px 1px 20px 10px rgba(250, 148, 0, 0.4);
    box-shadow: 1px 1px 10px 5px white;
    transform: scale(1.1);
    cursor: pointer;
    z-index: 2000;
    transition: all 450ms
  }
  
  .btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: -120%;
    width: 30vh;
    height: 30vh;
    background: linear-gradient(
      60deg,
      transparent,
      rgba(252, 252, 252, 0.1),
      transparent
    );
    transition: all 450ms;
  }
  
  .btn:hover:before {
    left: 100%;
  }


  .myHeader {
    position: absolute;
    width: fit-content;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    top: 11vh;
    font-family: "Audiowide", cursive;
    font-size: 2rem;
    color: rgba(250, 148, 0, 1);
    // background-color: #ffdd40;
    text-shadow: 2px 2px 2px #000;
    cursor: default;
}

.headerText {
    font-family: "Audiowide", cursive;
    text-shadow: 2px 2px 2px #000;
    color: rgba(250, 148, 0, 1);
}

.filter-container {
    position: absolute;
    right: 0;
    top: 10vh;
}

@keyframes fade-in {
    0% {opacity: 0;}
    100%{opacity: 1;;}
}

.backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 3004;
    background-color: rgba(100, 100, 100, 0.5);
}