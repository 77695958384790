/* calendar white */

.calendar_white_main {
    --color-event-background: #5794D0;
    --color-event-text: #ffffff;
    --color-event-border: #ffffff;
    --color-header-background: #ffffff;
    --color-header-text: #777777;
    --color-cell-background-business: #ffffff;
    --color-cell-background-nonbusiness: #f9f9f9;
}

.calendar_white_main {
    border: 1px solid #ccc;
    font-size: 13px;
}

.calendar_white_colheader {
}

.calendar_white_scroll_up {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAB3RJTUUH2wESDiYcrhwCiQAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAARnQU1BAACxjwv8YQUAAACcSURBVHjaY2AgF9wWsTW6yGMlhi7OhC7AyMDQzMnBXIpFHAFuCtuaMTP+P8nA8P/b1x//FfW/HHuF1UQmxv+NUP1c3OxMVVhNvCVi683E8H8LXOY/w9+fTH81tF8fv4NiIpBRj+YoZtZ/LDUoJmKYhsVUpv0MDiyMDP96sIYV0FS2/8z9ICaLlOhvS4b/jC//MzC8xBG0vJeF7GQBlK0xdiUzCtsAAAAASUVORK5CYII=);
}

.calendar_white_scroll_down {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAALiMAAC4jAXilP3YAAACqSURBVChTY7wpam3L9J+xmQEP+PGPKZZxP4MDi4zI78uMDIwa2NT+Z2DYovrmiC+TI8OBP/8ZmEqwGvif4e8vxr+FIDkmEKH25vBWBgbG0+iK/zEwLtF+ffwOXCGI8Y+BoRFFIdC030x/WmBiYBNhpgLdswNJ8RSYaSgmgk39z1gPUfj/29ef/9rwhQTDHRHbrbdEbLvRFcGthkkAra/9/uMvhkK8piNLAgCRpTnNn4AEmAAAAABJRU5ErkJggg==);
}

.calendar_white_rowheader_inner,
.calendar_white_cornerright_inner,
.calendar_white_corner_inner,
.calendar_white_alldayheader_inner {
    color: var(--color-header-text);
    background: var(--color-header-background);
}
.calendar_white_colheader_inner {
    color: var(--color-header-text);
}

.calendar_white_cornerright_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-bottom: 1px solid #ccc;
}

.calendar_white_rowheader_inner {
    font-size: 16pt;
    text-align: right;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-right: 4px;
}

.calendar_white_corner_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.calendar_white_rowheader_minutes {
    font-size: 10px;
    vertical-align: super;
    padding-left: 2px;
    padding-right: 2px;
}

.calendar_white_colheader_inner {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    display: flex; align-items: center; justify-content: center;
}

.calendar_white_cell_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    background-color: var(--color-cell-background-nonbusiness);
}

.calendar_white_cell_business .calendar_white_cell_inner {
    background-color: var(--color-cell-background-business);
}

.calendar_white_alldayheader_inner {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.calendar_white_message {
    opacity: 0.9;
    padding: 10px;
    color: #ffffff;
    background: #ffa216;
}

.calendar_white_alldayevent_inner,
.calendar_white_event_inner {
    color: var(--color-event-text);
    background-color: var(--color-event-background);
    border-radius: 5px;
    border: 1px solid var(--color-event-border);
}

.calendar_white_selected .calendar_white_event_inner {
    background: #ddd;
}

.calendar_white_alldayevent_inner {
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0px;
    right: 1px;
    padding: 4px;
    margin-right: 1px;
    font-size: 13px;

    display: flex;
    align-items: center;

}

.calendar_white_event_withheader .calendar_white_event_inner {
    padding-top: 15px;
}

.calendar_white_event {
    cursor: default;
}

.calendar_white_event_inner {
    position: absolute;
    overflow: hidden;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 5px;
    font-size: 13px;
}

.calendar_white_shadow_inner {
    background-color: #666666;
    opacity: 0.5;
    height: 100%;
    border-radius: 5px;
}

.calendar_white_event_delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAI5JREFUKFNtkLERgCAMRbmzdK8s4gAUlhYOYEHJEJYOYOEwDmGBPxC4kOPfvePy84MGR0RJ2N1A8H3N6DATwSQ57m2ql8NBG+AEM7D+UW+wjdfUPgerYNgB5gOLRHqhcasg84C2QxPMtrUhSqQIhg7ypy9VM2EUZPI/4rQ7rGxqo9sadTegw+UdjeDLAKUfhbaQUVPIfJYAAAAASUVORK5CYII=) center center no-repeat;
    opacity: 0.6;
    cursor: pointer;
}

.calendar_white_event_delete:hover {
    opacity: 1;
}

.calendar_white_scroll_up {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAB3RJTUUH2wESDiYcrhwCiQAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAARnQU1BAACxjwv8YQUAAACcSURBVHjaY2AgF9wWsTW6yGMlhi7OhC7AyMDQzMnBXIpFHAFuCtuaMTP+P8nA8P/b1x//FfW/HHuF1UQmxv+NUP1c3OxMVVhNvCVi683E8H8LXOY/w9+fTH81tF8fv4NiIpBRj+YoZtZ/LDUoJmKYhsVUpv0MDiyMDP96sIYV0FS2/8z9ICaLlOhvS4b/jC//MzC8xBG0vJeF7GQBlK0xdiUzCtsAAAAASUVORK5CYII=);
}

.calendar_white_scroll_down {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAALiMAAC4jAXilP3YAAACqSURBVChTY7wpam3L9J+xmQEP+PGPKZZxP4MDi4zI78uMDIwa2NT+Z2DYovrmiC+TI8OBP/8ZmEqwGvif4e8vxr+FIDkmEKH25vBWBgbG0+iK/zEwLtF+ffwOXCGI8Y+BoRFFIdC030x/WmBiYBNhpgLdswNJ8RSYaSgmgk39z1gPUfj/29ef/9rwhQTDHRHbrbdEbLvRFcGthkkAra/9/uMvhkK8piNLAgCRpTnNn4AEmAAAAABJRU5ErkJggg==);
}

.calendar_white_now {
    background-color: red;
}

.calendar_white_now:before {
    content: '';
    top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent red;
    border-style: solid;
    width: 0px;
    height: 0px;
    position: absolute;
    -moz-transform: scale(.9999);
}

.calendar_white_shadow_forbidden .calendar_white_shadow_inner {
    background-color: red;
}

.calendar_white_shadow_top {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.calendar_white_shadow_bottom {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.calendar_white_crosshair_vertical, .calendar_white_crosshair_horizontal, .calendar_white_crosshair_left, .calendar_white_crosshair_top {
    background-color: gray;
    opacity: 0.2;
    filter: alpha(opacity=20)
}

.calendar_white_loading {
    background-color: orange;
    color: white;
    padding: 2px;
}
